import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { TermsComponent } from '../terms/terms.component';
import { UnsubscribeComponent } from '../unsubscribe/unsubscribe.component';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {
  constructor(public popoverController: PopoverController,
    private alertController: AlertController,
    private modalController:ModalController) {}

  ngOnInit() {}
  logout() {
    this.popoverController.dismiss('logout');
  }
  async privacy(page) {
    const modal = await this.modalController.create({
      component: TermsComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        initialPage: page,
      },
    });
    modal.onDidDismiss().then(async (data) => {
      console.log(data);
    });
    return await modal.present();
  }

  async deleteAccount(){
    const alert = await this.alertController.create({
      header: 'Eliminación de cuenta',
      message:
        'Desea continuar con el proceso de eliminación de cuenta y datos de usuario?',
      buttons: [
        {
          text: 'Sí, ¡Desea continuar!',
          handler: async () => {
            await this.showForm();
          },
        },
        {
          text: 'No',
          role: 'cancel'
        },
      ],
    });
    await alert.present();
  }

  async showForm(){
    const modal = await this.modalController.create({
      component: UnsubscribeComponent
    });
    modal.onDidDismiss().then(async (res) => {
      console.log(res);
      if (res.data === 'delete_account'){
        this.logout();
      }
    });
    return await modal.present();
  }
}
