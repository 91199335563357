import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss'],
})
export class UnsubscribeComponent implements OnInit {

  deleteAccountForm: FormGroup;
  user: any = {};
  constructor(private formBuilder: FormBuilder,
    private modalController: ModalController,
    private authService: AuthService,
    private toast: ToastController,
    private alertController: AlertController

  ) {
    this.deleteAccountForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      reason: [''],
      other_reason: [''],
      consent: [false, Validators.requiredTrue],
    });
  }
  get email() {
    return this.deleteAccountForm.get('email');
  }
  async onSubmit() {
    if (this.deleteAccountForm.valid) {
      //console.log(this.user, 'user>>', this.email.value)
      if (this.user.email === this.email.value){
      // Aquí puedes manejar la lógica para enviar la solicitud de eliminación
      // console.log('Formulario enviado:', this.deleteAccountForm.value);
        const data: any = JSON.parse(JSON.stringify(this.deleteAccountForm.value));
        // const data =  {
        //   email: this.email.value,
        //   code: this.deleteAccountForm.get('code').value,
        //   confirm: false,
        //   reason: this.deleteAccountForm.get('reason').value,
        //   other_reason: this.deleteAccountForm.get('other_reason').value,
        // }
        this.authService.deleteAccount(data).subscribe((res: any)=>{
          if (res.status === 201) {
            this.confirmCodeAlert(data);
          } else {
            console.log(res)
          }
        }, (e)=>{
          if (e.error.status === 422) {
            this.presentToast(e.error.motives.message, 'warning');
          } else {
            this.presentToast('¡Ha ocurrido un error, por favor intente más tarde!', 'danger');
          }
        })
      // Implementa la lógica para enviar los datos a tu backend o API
      } else {
        const alert = await this.alertController.create({
          header: 'Eliminar cuenta',
          message: '¡El correo no coincide con el usuario en sesión!',
          buttons: ['OK'],
        });
        await alert.present();
      }
    }
  }
  async ngOnInit() {
    this.user = await this.authService.getUser();
  }

  dismiss(data = '') {
    this.modalController.dismiss(data);
  }

  async confirmCodeAlert(data) {
    const alert = await this.alertController.create({
      header: 'Código de Verificación',
      message: 'Ingresa el código envido a tu correo electrónico',
      inputs: [
        {
          name: 'code',
          type: 'text',
          placeholder: 'Código',
        }
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          },
        },
        {
          text: 'Validar',
          handler: (input) => {
            console.log('Código ingresado:', input);
            data.code = input.code;
            data.confirm = 1;
            this.authService.deleteAccount(data).subscribe((res: any)=>{
              if(res.status === 202) {
                this.confirmDeleteAccount(res.message);
              } else {
                console.log(res)
              }
            }, (e)=>{
              if (e.error.status === 422) {
                this.presentToast(e.error.motives.message, 'warning');
              } else {
                this.presentToast('¡Ha ocurrido un error, por favor intente más tarde!', 'danger');
              }
            })
            // Aquí puedes manejar el código ingresado como desees
          },
        },
      ],
    });

    await alert.present();
  }

  async confirmDeleteAccount(message){
    const alert = await this.alertController.create({
      header: 'Eliminar cuenta',
      message,
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.dismiss('delete_account')
          },
        },
      ],
    });
    await alert.present();
  }

  async presentToast(msg, type) {
    const toast = await this.toast.create({
      message: msg,
      duration: 2000,
      color: type,
      position: 'top',
    });
    toast.present();
  }
}
