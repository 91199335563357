import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComponentsRoutingModule } from './components-routing.module';
import { CardListComponent } from './card-list/card-list.component';
import { ExpandableComponent } from './expandable/expandable.component';
import { SlidesComponent } from './slides/slides.component';
import { IonicModule } from '@ionic/angular';
import { PopoverComponent } from './popover/popover.component';
import { SafePipe } from '../pipes/safe.pipe';
import { VideoPlayComponent } from './video-play/video-play.component';
import { DescriptionModalComponent } from './description-modal/description-modal.component';
import { SafeHtmlPipe } from '../pipes/safe-html.pipe';
import { VideoPopoverComponent } from './video-popover/video-popover.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TimerComponent } from './timer/timer.component';
import { PaymentComponent } from './payment/payment.component';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { TrainingDescriptionComponent } from './training-description/training-description.component';
import { TermsComponent } from './terms/terms.component';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { VerifyComponent } from './verify/verify.component';
import { WaButtonComponent } from './wa-button/wa-button.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';

@NgModule({
  declarations: [
    CardListComponent,
    ExpandableComponent,
    SlidesComponent,
    PopoverComponent,
    SafePipe,
    VideoPlayComponent,
    DescriptionModalComponent,
    SafeHtmlPipe,
    VideoPopoverComponent,
    TimerComponent,
    PaymentComponent,
    SubscriptionsComponent,
    TrainingDescriptionComponent,
    TermsComponent,
    PdfViewerComponent,
    VerifyComponent,
    WaButtonComponent,
    UnsubscribeComponent
  ],
  imports: [
    CommonModule,
    ComponentsRoutingModule,
    IonicModule,
    PdfViewerModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    CardListComponent,
    ExpandableComponent,
    SlidesComponent,
    PopoverComponent,
    VideoPlayComponent,
    DescriptionModalComponent,
    VideoPopoverComponent,
    TimerComponent,
    SafePipe,
    PaymentComponent,
    SubscriptionsComponent,
    TrainingDescriptionComponent,
    SafeHtmlPipe,
    VerifyComponent,
    WaButtonComponent,
    UnsubscribeComponent
  ],
  providers: [InAppBrowser],
})
export class ComponentsModule {}
